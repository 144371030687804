.App {
  text-align: center;
}

.projects {
  display: flex;
  flex-direction: row;
}

.projectobject {
  position: relative;
  flex: 0 0 auto;
  min-height: 300px;
  max-height: 300px;
  min-width: 300px;
  max-width: 300px;
  padding: 14px;
}

.projimg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 300px;
  max-height: 310px;
  min-height: 310px;
  border: 1px solid black;
  border-radius: 10px;
}

.projtitle {
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Section-header {
  background-color: #333;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Footer {
  grid-row: 3;
  padding: 20px;
  text-align: center;
}

.footer-text {
  margin: 0;
}

.App-header {
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: #333;
  color: #fff;
}

.header__logo {
  font-size: 2rem;
}

.header__nav {
  display: flex;
}

.header__nav a {
  display: block;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
}

.main {
  padding: 2rem;
}

ul {
  list-style: none;
}
